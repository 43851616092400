<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Performance Analysis Grade Entry
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :loading="examLoading"
                  :disabled="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  :disabled="!exam || !grades.length"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="!grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
        
          <v-data-table
            v-if="gradeRating === 'grading'"
            :headers="headers"
            :hide-default-footer="
              (form.items.data && form.items.data.length < 1) ||
                form.items.meta.total < pagination.itemsPerPage
            "
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            footer-props.items-per-page-options="rowsPerPageItems"
            class="marks_entry"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-center">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-center" v-for="(x, i) in head" :key="i">
                  <span v-if="head.length > 0">
                    <select
                      class="performances"
                      v-model="paData[index].grading[i].grade"
                      :disabled="disabledEntry"
                    >
                      <option v-for="(a, b) in performances" :key="b">{{
                        a.text
                      }}</option>
                    </select>
                    <p>{{ paData[index].grading[i].performance }}</p>
                  </span>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-data-table
            v-if="gradeRating === 'rating'"
            :headers="headers"
            :hide-default-footer="
              (form.items.data && form.items.data.length < 1) ||
                form.items.meta.total < pagination.itemsPerPage
            "
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            footer-props.items-per-page-options="rowsPerPageItems"
            class="marks_entry"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-center">{{ item.roll }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-center" v-for="(x, i) in head" :key="i">
                  <span v-if="head.length > 0">
                    <select
                      class="performances"
                      v-model="paData[index].grading[i].grade"
                      :disabled="disabledEntry"
                    >
                      <option
                        v-for="(a, b) in performancesRating"
                        :value="a.value"
                        :key="b"
                        >{{ a.text }}</option
                      >
                    </select>
                    <p>{{ paData[index].grading[i].performance }}</p>
                  </span>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>

          <br />
          <v-container>
            <alert-message
              v-if="gradeRating === 'numbering'"
              :extraSmall="false"
              type="error"
              title=" Incorrect Setting "
              message="Switch the grading type to numbering to enable performance analysis"
              ></alert-message>
         
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="success"
                v-if="form.items.data.length > 1"
                outlined
                @click="save"
                :disabled="disabledEntry"
                >{{ update ? "Update" : "Save" }}
              </v-btn>
            </v-card-actions>
          </v-container>
          <br />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { getPropertyFromArrayObject } from "@/library/helpers";

export default {
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_verify: 0,
      },
      "/api/exam/performance/student"
    ),
    search: null,
    pagination: {
      itemsPerPage: 200,
      sortBy: "roll",
    },
    gradeRating: "",
    disabledEntry: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
    is_verify: 0,
    dataVerfiy: false,
    update: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    paData: [],
    headers: [],
    head: [],
    verify_status: {},
    subject_marks_theory: 0,
    subject_marks_practical: 0,
    marks_entry: {},
    evaluation: "",
    performances: [
      { text: "A+", value: "A+" },
      { text: "A", value: "A" },
      { text: "B+", value: "B+" },
      { text: "B", value: "B" },
      { text: "C+", value: "C+" },
      { text: "C", value: "C" },
      { text: "D+", value: "D+" },
      { text: "D", value: "D" },
      { text: "E", value: "E" },
    ],
    performancesRating: [
      { text: "*", value: "1" },
      { text: "**", value: "2" },
      { text: "***", value: "3" },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
  },
  mounted() {
    this.getExams();
  },
  watch: {
    grade: function() {
      this.section = "";
      this.form.items.data = [];
      this.getSections();
      if (this.grade) {
        this.getGradeRatingStatus();
      }
    },
    exam: function() {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
    section: function() {
      this.get();
    },
    pagination: function() {
      this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade.toString() +
        "&examId=" +
        this.exam.toString() +
        "&sectionId=" +
        this.section.toString()
      );
    },

    get(params) {
      if (this.section) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        query = query.replace("&descending=false&page=1", "");
        this.form.get(null, query).then(({ data }) => {
          if (data.mark_entry_duration == "false") {
            this.disabledEntry = true;
          } else {
            this.disabledEntry = false;
          }
          // console.log(data.marks_entry_duration);
          this.paData = [];
          this.headers = [];
          this.pagination.totalItems = data.meta.total;

          this.headers = [
            {
              text: "Roll",
              align: "center",
              value: "roll",
              sortable: false,
              width: 30,
            },
            { text: "Name", align: "left", value: "name", sortable: false },
          ];
          this.head = data.pa.map((h) => {
            this.paData.map((res) => {
              res.grading.push({
                enroll_id: "",
                grade: "",
                pa_id: "",
                performance: "",
              });
            });
            return {
              text: h.name,
              align: "center",
              value: h.name,
              sortable: false,
              id: h.id,
            };
          });
          data.data.map((res) => {
            this.paData.push({
              id: "",
              name: "",
              grading: [],
            });
          });
          if (data.data[0].grading.length > 0)
            data.data[0].grading.map((res) => {
              this.headers.push({
                text: res.performance,
                align: "center",
                sortable: false,
              });
            });
          else {
            this.headers = [];
            this.headers = [
              {
                text: "Roll",
                align: "center",
                value: "roll",
                sortable: false,
                width: 30,
              },
              { text: "Name", align: "left", value: "name", sortable: false },
            ];
            data.pa.map((h) => {
              this.headers.push({
                text: h.name,
                align: "center",
                value: h.name,
                sortable: false,
                id: h.id,
              });
            });

            this.paData = data.data.map(function(item) {
              if (item.grading.length === data.pa.length) {
                return item;
              } else {
                data.pa.map((pa) => {
                  let itemGradingsNow = getPropertyFromArrayObject(
                    "pa_id",
                    item.grading
                  );
                  // console.log(itemGradingsNow, allGradeId);
                  if (!itemGradingsNow.includes(pa.id)) {
                    item.grading.push({
                      enroll_id: item.enroll_id,
                      grade: "",
                      pa_id: pa.id,
                      performance: pa.name,
                    });
                  }
                });
                return item;
              }
            });
          }

          let allGradeId = getPropertyFromArrayObject("id", data.pa);
          if (data.grade_count > 0) {
            this.paData = data.data.map(function(item) {
              if (item.grading.length === data.pa.length) {
                return item;
              } else {
                data.pa.map((pa) => {
                  let itemGradingsNow = getPropertyFromArrayObject(
                    "pa_id",
                    item.grading
                  );
                  // console.log(itemGradingsNow, allGradeId);
                  if (!itemGradingsNow.includes(pa.id)) {
                    item.grading.push({
                      enroll_id: item.enroll_id,
                      grade: "",
                      pa_id: pa.id,
                      performance: pa.name,
                    });
                  }
                });
                return item;
              }
            });
            this.update = true;
          } else {
            this.update = false;
          }
          // console.log(this.paData);
          // console.log(data.data)
        });
      }
    },

    save() {
      // console.log(this.paData);
      this.form.students = this.paData;
      this.form.exam_id = this.exam;
      this.form.grade_id = this.grade;
      this.form.store();

      // this.form.students = this.form.items.data.map(function (item) {
      //     if (item.theory === 'Absent') {
      //         item.theory = 'I';
      //     }
      //     if (item.practical === 'Absent') {
      //         item.practical = 'I';
      //     }
      //     return item;
      // });
      // this.form.grade_id = this.grade;
      // this.form.exam_id = this.exam;
      // this.form.subject_id = this.subject;
      // this.form.section_id = this.section;
      // this.form.is_verify = this.is_verify ? 1 : 0;
      // this.form.fireFetch = false;
      // this.form.store().then(res => {
      //     this.is_verify = 0;
      //     this.get();
      // });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              evaluation: item.evaluation,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    // paDataChange(hi, vi, data) {
    //
    //     this.paData[hi].grading[vi].enroll_id = data.enroll_id;
    //     this.paData[hi].grading[vi].pa_id = this.head[vi].id;
    //
    // }
    getGradeRatingStatus() {
      this.$rest.get("api/marksheet-type?gradeId=" + this.grade).then((res) => {
        this.gradeRating = res.data.marksheet_type;
      });
    },
  },
};
</script>
<style lang="scss">
.performances {
  width: 37px;
  border: solid 1px #777;
  padding-left: 10px;
}

p {
  margin-bottom: -8px !important;
  font-size: 9px !important;
}

select.performances:disabled {
  background: #eee;
  cursor: not-allowed;
}
</style>
